<template>
  <el-card
    shadow="always"
    :body-style="{
      display: 'flex',
      'align-items': 'center',
      'justify-content': 'space-between',
      height: '120px',
    }"
    class="rank-card"
  >
    <div class="user">
      <el-avatar
        icon="el-icon-user-solid"
        :size="67"
        :src="userInfo.avatar"
        class="user-avatar"
      ></el-avatar>
      <div class="user-info">
        <p class="username">{{ userInfo.name }}</p>
        <p class="title">{{ userInfo.roleName||'小小编程家' }}</p>
      </div>
    </div>
    <ul class="data">
      <li class="data-item">
        <div class="item-title">作品数量</div>
        <div class="item-content">
          <img src="@/assets/image/rank-img/codes-white.png" alt="" />
          <div class="number">{{ userInfo.workNum }}</div>
        </div>
      </li>
      <li class="data-item">
        <div class="item-title">代码行数</div>
        <div class="item-content">
          <img src="@/assets/image/rank-img/code-white.png" alt="" />
          <div class="number">{{ userInfo.codeLines }}</div>
        </div>
      </li>
      <li class="data-item">
        <div class="item-title">收藏</div>
        <div class="item-content">
          <img src="@/assets/image/rank-img/star-white.png" alt="" />
          <div class="number">{{ userInfo.collectNum }}</div>
        </div>
      </li>
      <li class="data-item">
        <div class="item-title">点赞</div>
        <div class="item-content">
          <img src="@/assets/image/rank-img/support-white.png" alt="" />
          <div class="number">{{ userInfo.starNum }}</div>
        </div>
      </li>
      <li class="data-item">
        <div class="item-title">播放</div>
        <div class="item-content">
          <img src="@/assets/image/rank-img/view-white.png" alt="" />
          <div class="number">{{ userInfo.viewNum }}</div>
        </div>
      </li>
    </ul>
    <div class="user-rank">
      <div class="ranking">编程排名：{{ userInfo.ranking || '未上榜' }}</div>
      <RankGift />
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    userInfo: Object,
  },
  components: {
    RankGift: () => import('./RankGift.vue'),
  },
};
</script>

<style lang="scss" scoped>
.rank-card {
  color: #fff;
  background-image: url('./../../assets/image/rank-img/user-banner.png');
  background-size:130% 130%;
  background-position: center;
  .user {
    display: flex;
    .user-avatar {
      width: 62px;
      height: 62px;
      margin-right: 20px;
    }
    .user-info {
      display: flex;
      flex-flow: column nowrap;
      font-weight: bold;
      justify-content: space-around;
      font-size: 16px;
    }
  }
  .data {
    display: flex;
    align-items: center;
    .data-item {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      margin: 0 30px;
      .item-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .item-content {
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
        .number {
          font-size: 12px;
        }
      }
    }
  }
  .user-rank {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    .ranking {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
}
</style>
